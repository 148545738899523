import InternalAttribute, { AttributeProps } from './attribute';
import AttributeBoolean, { AttributeBooleanProps } from './attribute-boolean';
import AttributeDate, { AttributeDateProps } from './attribute-date';
import AttributeDayOffType, { AttributeDayOffTypeProps } from './attribute-day-off-type';
import AttributeFile, { AttributeFileProps } from './attribute-file';
import AttributeHint, { AttributeHintProps } from './attribute-hint';
import AttributeHolidays, { AttributeHolidaysProps } from './attribute-holidays';
import AttributeInteger, { AttributeIntegerProps } from './attribute-integer';
import AttributeMultifile, { AttributeMultifileProps } from './attribute-multifile';
import AttributeOptions, { AttributeOptionsProps } from './attribute-options';
import AttributeSchedule, { AttributeScheduleProps } from './attribute-schedule';
import AttributeString, { AttributeStringProps } from './attribute-string';
import AttributeTextArea, { AttributeTextAreaProps } from './attribute-text-area';
import AttributeVacation, { AttributeVacationProps } from './attribute-vacation';
import AttributeWorkbookType, { AttributeWorkbookTypeProps } from './attribute-workbook-type';
import AttributeWorkdayType, { AttributeWorkdayTypeProps } from './attribute-workday-type';
import AttributeYearsList, { AttributeYearsListProps } from './attribute-years-list';

export type AttributeCompoundedComponents = typeof InternalAttribute & {
  Boolean: typeof AttributeBoolean;
  Date: typeof AttributeDate;
  File: typeof AttributeFile;
  Hint: typeof AttributeHint;
  Holidays: typeof AttributeHolidays;
  Integer: typeof AttributeInteger;
  Multifile: typeof AttributeMultifile;
  Schedule: typeof AttributeSchedule;
  String: typeof AttributeString;
  TextArea: typeof AttributeTextArea;
  Vacation: typeof AttributeVacation;
  WorkbookType: typeof AttributeWorkbookType;
  WorkdayType: typeof AttributeWorkdayType;
  DayOffType: typeof AttributeDayOffType;
  YearsList: typeof AttributeYearsList;
  Options: typeof AttributeOptions;
};

const Attribute = InternalAttribute as AttributeCompoundedComponents;
Attribute.Boolean = AttributeBoolean;
Attribute.Date = AttributeDate;
Attribute.File = AttributeFile;
Attribute.Hint = AttributeHint;
Attribute.Holidays = AttributeHolidays;
Attribute.Integer = AttributeInteger;
Attribute.Multifile = AttributeMultifile;
Attribute.Schedule = AttributeSchedule;
Attribute.String = AttributeString;
Attribute.TextArea = AttributeTextArea;
Attribute.Vacation = AttributeVacation;
Attribute.WorkbookType = AttributeWorkbookType;
Attribute.WorkdayType = AttributeWorkdayType;
Attribute.YearsList = AttributeYearsList;
Attribute.Options = AttributeOptions;
Attribute.DayOffType = AttributeDayOffType;

export type {
  AttributeProps,
  AttributeBooleanProps,
  AttributeDateProps,
  AttributeFileProps,
  AttributeIntegerProps,
  AttributeHintProps,
  AttributeHolidaysProps,
  AttributeMultifileProps,
  AttributeScheduleProps,
  AttributeStringProps,
  AttributeTextAreaProps,
  AttributeVacationProps,
  AttributeWorkbookTypeProps,
  AttributeWorkdayTypeProps,
  AttributeYearsListProps,
  AttributeOptionsProps,
  AttributeDayOffTypeProps,
};
export { getAttributeComponentType } from './attribute.utils';
export default Attribute;
