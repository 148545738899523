import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import dayjs from 'dayjs';
import { useLocation, useMatch, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  DOCUMENTS_ROUTE,
  DOCUMENTS_SIGN_CODE_ROUTE,
  DOCUMENTS_SIGN_LIST_ROUTE,
  DOCUMENTS_STATEMENT_REQUEST_ROUTE,
  getDocumentsInquiriesDetailsRoute,
  getDocumentsStatementsDetailsRoute,
} from 'routes/documents/list';
import { t } from 'tools/i18n';
import useCertificate from 'utils/useCertificate.hook';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import {
  useCreateKedoTaskMutation,
  useGetKedoCategoriesQuery,
  useLazyGetKedoStatementByIdQuery,
  usePrepareKedoTaskMutation,
  useSaveDraftKedoTaskMutation,
} from 'services/kedo/kedoApiService';
import { setActiveKedoFormTask, setActiveKedoTask } from 'slices/kedo';
import { useAppDispatch } from 'store';

import { Button, FormInstance, Icon, Typography, handleRequest } from 'gazprom-ui-lib';

import Attribute, {
  AttributeCompoundedComponents,
  getAttributeComponentType,
} from 'containers/attribute';
import { AttributeDefaultProps, transformRequest } from 'containers/attribute/attribute.utils';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import s from 'pages/documents/documents-create-request/DocumentsCreateRequest.module.scss';

import { SERVICE_TAGS } from 'constants/serviceTags';
import { KEDO_COPY_FILE_TYPES, PreparedKedoTask, TASK_TYPE_ENUM } from 'types/documents.types';

import DocumentsErrorModal from '../documents-error-modal';
import DocumentsCreateNoCertificateModal from './documents-create-no-certificate-modal';
import DocumentsCreateRequestDeliver from './documents-create-request-deliver';
import { customStatement } from './documentsCreateRequest.utils';

const DocumentsCreateRequest = () => {
  const { statementId = '' } = useParams<{ statementId: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const [selectedEmployee] = useFullSelectedEmployee();

  const [modalVisible, setModalVisible] = useState<'create' | 'noCertificate' | 'error' | ''>('');

  const [getKedoStatement, { data: statement, isLoading: isStatementLoading }] =
    useLazyGetKedoStatementByIdQuery();

  useEffect(() => {
    if (statementId && selectedEmployee) {
      getKedoStatement({
        statementId,
        myEmployeeId: selectedEmployee?.id!,
        fromId: location?.state?.fromId,
      });
    }
  }, [getKedoStatement, location?.state?.fromId, selectedEmployee, statementId]);

  const isRequestSend = useRef<boolean>(false);

  const { isActive: isActiveCertificate } = useCertificate();
  const [prepareTask, { isLoading: prepareTaskIsLoading }] = usePrepareKedoTaskMutation();
  const [createKedoTask, { isLoading: isCreateLoading }] = useCreateKedoTaskMutation();
  const [saveDraft] = useSaveDraftKedoTaskMutation();
  const { data: categories, isLoading: isCategoriesLoading } = useGetKedoCategoriesQuery({
    type: TASK_TYPE_ENUM.BPMN,
  });

  const isLoading = isCategoriesLoading || isStatementLoading;
  const isButtonLoading = isCreateLoading || prepareTaskIsLoading;
  const isStatementRoute = !!useMatch(DOCUMENTS_STATEMENT_REQUEST_ROUTE);
  const buttonText = t(isStatementRoute ? 'common_sign' : 'documents_inquiries_request');

  const headerChildren = (
    <>
      <Header.GoBack to={DOCUMENTS_ROUTE} />
      <Header.Label
        label={t(isStatementRoute ? 'documents_apply' : 'documents_inquiries_request')}
      />
    </>
  );

  const handleModalOk = () => {
    setModalVisible('');
    navigate(DOCUMENTS_ROUTE);
  };

  const category = useMemo(() => {
    return categories?.find((category) => category.id === statement?.categoryId);
  }, [categories, statement]);

  const onFinish = (values: { [key: string]: string }) => {
    if (statement?.attributes && selectedEmployee) {
      let request = {
        statementId,
        ...transformRequest({
          values,
          attributes: statement?.attributes,
          hasDigitalForm: statement?.hasDigitalForm,
        }),
      };

      const onSuccess = (res?: { id: string }) => {
        isRequestSend.current = true;
        if (res) {
          navigate(getDocumentsInquiriesDetailsRoute(res.id), { state: { modalType: 'created' } });
        }
      };

      createKedoTask({
        selectedEmployee: selectedEmployee.id,
        body: request,
      })
        .then(
          handleRequest({
            onSuccess,
          }),
        )
        .catch(() => setModalVisible('error'));
    }
  };

  const onFinishWithSign = <R extends Record<string, unknown>>(values: R) => {
    if (!isActiveCertificate) {
      return setModalVisible('noCertificate');
    }

    if (statement?.attributes && selectedEmployee) {
      dispatch(setActiveKedoFormTask(values));
      let request = {
        statementId: statementId || '',
        ...transformRequest({
          values,
          attributes: statement?.attributes,
          hasDigitalForm: statement?.hasDigitalForm,
        }),
        fromId: location?.state?.fromId,
      };

      const onSuccess = (task?: PreparedKedoTask) => {
        if (task) {
          const navigateParams = {
            state: {
              cameFrom: getDocumentsStatementsDetailsRoute(task.taskId),
              modalType: 'created',
              invalidateTags: [SERVICE_TAGS.GET_KEDO_TASKS, SERVICE_TAGS.GET_ASSIGNED_TASKS],
              mock: !!searchParams.get('mock'),
              type: 'confirmAssignment',
              taskType: KEDO_COPY_FILE_TYPES.KEDO_TASK,
              taskId: task.taskId,
            },
          };

          if (task.filesToSign.length) {
            dispatch(setActiveKedoTask(task));
            navigate(DOCUMENTS_SIGN_LIST_ROUTE, navigateParams);
          } else {
            navigate(DOCUMENTS_SIGN_CODE_ROUTE, navigateParams);
          }
        }
      };

      prepareTask({
        selectedEmployee: selectedEmployee.id,
        body: request,
      }).then(
        handleRequest({
          onSuccess,
        }),
      );
    }
  };

  const handleSaveDraft = useCallback(
    (form: FormInstance) => {
      const shouldSaveDraft =
        !!selectedEmployee?.id && !statement?.fromId && !isRequestSend.current;

      if (statement?.attributes && shouldSaveDraft) {
        let request = {
          statementId,
          ...transformRequest({
            values: form.getFieldsValue(),
            attributes: statement.attributes,
            hasDigitalForm: statement?.hasDigitalForm,
          }),
        };

        saveDraft({
          myEmployeeId: selectedEmployee.id,
          id: statement.kedoDraftId,
          body: request,
        });
      }
    },
    [saveDraft, selectedEmployee?.id, statement, statementId],
  );

  const CustomComponent = customStatement[statementId as keyof typeof customStatement];

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren} showNavbar={false}>
        <WithBackground>
          {!CustomComponent && statement?.kedoDraftUpdatedAt && (
            <Card className={s.infoCard}>
              <Icon name="info" />
              <Typography.Text size="14">
                {t('documents_continue_draft', {
                  type: isStatementRoute
                    ? t('common_statement').toLowerCase()
                    : t('documents_inquiry_draft').toLowerCase(),
                })}
                <br /> {dayjs(statement?.kedoDraftUpdatedAt).format('DD.MM.YYYY')}
              </Typography.Text>
            </Card>
          )}
          <Attribute
            onSaveDraft={handleSaveDraft}
            onFinish={isStatementRoute ? onFinishWithSign : onFinish}>
            <Card>
              <WithLoader isLoading={isLoading}>
                <Typography.Text size="12" type="secondary">
                  {category?.name}
                </Typography.Text>
                <Typography.Title level={4}>{statement?.name}</Typography.Title>
                <Typography.Text size="14">{statement?.description}</Typography.Text>
              </WithLoader>
            </Card>
            <WithLoader isLoading={isLoading}>
              {CustomComponent && (
                <CustomComponent isButtonLoading={isButtonLoading} buttonText={buttonText} />
              )}
            </WithLoader>
            {!!statement?.attributes?.length && (
              <Card>
                {statement.attributes.map((attr) => {
                  const type = getAttributeComponentType(
                    attr.type,
                  ) as keyof AttributeCompoundedComponents;

                  const Component = Attribute[type] as AttributeCompoundedComponents;

                  if (!Component) {
                    return <></>;
                  }
                  const defaultProps: AttributeDefaultProps = { ...attr };

                  if (type === 'File' || type === 'Multifile') {
                    defaultProps.files = statement.files as AttributeDefaultProps['files'];
                    defaultProps.onSaveDraft = handleSaveDraft;
                  }

                  if (type === 'Date') {
                    defaultProps.yearConfig = {
                      initialMaxYear: dayjs().add(3, 'year').year(),
                    };
                  }

                  return <Component key={attr.id} {...defaultProps} />;
                })}
              </Card>
            )}
            {!isStatementRoute && (
              <Card>
                <DocumentsCreateRequestDeliver
                  draftValue={statement?.shippingAddress}
                  hasDigitalForm={statement?.hasDigitalForm}
                />
              </Card>
            )}
            {!CustomComponent && (
              <div className={s.fixedCard}>
                <Button
                  htmlType="submit"
                  fullWidth
                  loading={isButtonLoading}
                  rightIcon="arrowRight"
                  size="large"
                  type="primary">
                  {buttonText}
                </Button>
              </div>
            )}
          </Attribute>
          <DocumentsCreateNoCertificateModal
            isVisible={modalVisible === 'noCertificate'}
            onClickHandler={handleModalOk}
          />
          <DocumentsErrorModal
            isOpen={modalVisible === 'error'}
            handleClose={() => setModalVisible('')}
          />
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default DocumentsCreateRequest;
