import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { useGetAvailabilityQuery } from 'services/gift-service/giftApiService';

import { Flex, Typography } from 'gazprom-ui-lib';

import calendarDay from 'assets/icons/calendar-day.png';
import calendarHour from 'assets/icons/calendar-hour.png';
import calendarMin from 'assets/icons/calendar-min.png';
import newYearActive from 'assets/icons/newYearActive.png';
import newYearWaiting from 'assets/icons/newYearWaiting.png';

import s from './HomeNewYear.module.scss';

const HomeNewYear = () => {
  const { data } = useGetAvailabilityQuery();

  const { isAvailable, startDate } = data ?? {};

  const targetDate = dayjs(startDate);
  const [timeRemaining, setTimeRemaining] = useState({ days: 0, hours: 0, minutes: 0 });

  const isActive = dayjs().isAfter(dayjs(targetDate));

  useEffect(() => {
    if (!isActive) {
      const timer = setInterval(() => {
        const now = dayjs();
        const duration = targetDate.diff(now);

        if (duration > 0) {
          const days = Math.floor(duration / (1000 * 60 * 60 * 24));
          const hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
          setTimeRemaining({ days, hours, minutes });
        } else {
          clearInterval(timer);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isActive, targetDate]);

  if (!isAvailable) return null;

  if (isActive) {
    return <img src={newYearActive} alt="new-year-present-active" />;
  }

  return (
    <Flex className={s.wrapper}>
      <img src={newYearWaiting} alt="new-year-present-waiting" />

      <Flex gap="4" align="center" className={s.counterContent}>
        <Flex className={s.counterItem}>
          <img src={calendarDay} alt="calendar-day" />
          <Typography.Text type="primary" size="16" weight="500" className={s.counterItemLabel}>
            {timeRemaining.days}
          </Typography.Text>
        </Flex>
        <Typography.Text size="24" className={s.separator}>
          :
        </Typography.Text>
        <Flex className={s.counterItem}>
          <img src={calendarHour} alt="calendar-hour" />
          <Typography.Text type="primary" size="16" weight="500" className={s.counterItemLabel}>
            {timeRemaining.hours}
          </Typography.Text>
        </Flex>
        <Typography.Text size="24" className={s.separator}>
          :
        </Typography.Text>
        <Flex className={s.counterItem}>
          <img src={calendarMin} alt="calendar-minute" />
          <Typography.Text type="primary" size="16" weight="500" className={s.counterItemLabel}>
            {timeRemaining.minutes}
          </Typography.Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default HomeNewYear;
