import React, { FC } from 'react';

import cn from 'classnames';

import { Icon, TIconsLiteral } from 'gazprom-ui-lib';

import s from './HeaderRightIcon.module.scss';

interface HeaderRightIconProps {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  iconName: TIconsLiteral;
}

const HeaderRightIcon: FC<HeaderRightIconProps> = (props) => {
  const { iconName, className, onClick } = props;

  return (
    <button onClick={onClick} className={cn(s.wrapper, className)}>
      <Icon name={iconName} />
    </button>
  );
};

export default HeaderRightIcon;
