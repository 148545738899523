import {
  GetGiftAvailabilityPropsType,
  GetGiftAvailabilityResponseType,
} from 'services/gift-service/giftApiService.types';
import { createGiftUrl } from 'services/gift-service/utils';
import initialApiService from 'services/initialApiService';

const giftApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getAvailability: builder.query<GetGiftAvailabilityResponseType, GetGiftAvailabilityPropsType>({
      query: () => ({
        url: createGiftUrl('/availability'),
      }),
    }),
  }),
});

export const { useGetAvailabilityQuery } = giftApiService;
