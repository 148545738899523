import { ReactNode, useContext, useState } from 'react';

import { t } from 'tools/i18n';

import { Button, Form } from 'gazprom-ui-lib';

import { AssignmentContext } from 'containers/document-assignment/documentsAssignment';
import TextAreaModal from 'containers/textarea-modal';

import ApproveModal from '../approve-modal';
import RejectTaskModal from '../reject-task-modal';
import SignedModal from '../signed-modal';
import useSetModalFromLocationState from '../useSetModalFromLocationState.hook';
import { MODAL_TYPE } from '../utils';
import s from './ActionsKedoTask.module.scss';
import { ModalType } from './actionsKedoTask.utils';
import useActions from './useActions.hook';

interface Props {
  handleCertificateCheck: (callback: () => void) => void;
}

const ActionsKedoTask = (props: Props) => {
  const { handleCertificateCheck } = props;

  const assignment = useContext(AssignmentContext);

  const [modalType, setModalType] = useState<ModalType>('');

  const [form] = Form.useForm();
  const comment = Form.useWatch('comment', form);

  const handleModalClose = () => {
    setModalType('');
  };

  const handleRejectCommentModalOpen = () => {
    setModalType(MODAL_TYPE.REJECT_COMMENT);
  };

  const {
    isLoading,
    handleInfo,
    handleSign,
    handleApprove,
    handleRejectConfirm,
    handleCancellationConfirm,
    handleCancellationRejectConfirm,
  } = useActions({ setModalType, handleCertificateCheck, assignment });

  useSetModalFromLocationState({ setModalType });

  const areActionsAvailable = assignment?.status?.code === 'WAITING';

  const handleSignWithFamiliarizeModal = () => handleSign();
  const handleSignWithSignedModal = () => handleSign(MODAL_TYPE.SIGNED_KEDO);

  let buttons: ReactNode;

  if (assignment && areActionsAvailable) {
    if (assignment.type === 'INFO') {
      buttons = (
        <Button size="large" leftIcon="ok" fullWidth onClick={handleInfo} loading={isLoading}>
          {t('common_approve')}
        </Button>
      );
    }
    if (assignment.type === 'APPROVE') {
      buttons = (
        <>
          <Button size="large" fullWidth onClick={handleApprove} loading={isLoading}>
            {t('common_approve')}
          </Button>
          <Button
            fullWidth
            size="large"
            danger
            ghost
            onClick={handleRejectCommentModalOpen}
            loading={isLoading}>
            {t('common_reject')}
          </Button>
        </>
      );
    }
    if (assignment.type === 'CANCEL_ORG_REQUEST') {
      buttons = (
        <>
          <Button size="large" fullWidth onClick={handleCancellationConfirm} loading={isLoading}>
            {t('documents_confirm_cancellation')}
          </Button>
          <Button
            fullWidth
            size="large"
            danger
            ghost
            onClick={handleRejectCommentModalOpen}
            loading={isLoading}>
            {t('common_reject')}
          </Button>
        </>
      );
    }
    if (assignment.type === 'SIGN_EMPLOYEE') {
      buttons = (
        <Button
          size="large"
          fullWidth
          onClick={handleSignWithFamiliarizeModal}
          leftIcon="ok"
          loading={isLoading}>
          {t('common_familiarize')}
        </Button>
      );
    }

    if (assignment.type === 'SIGN') {
      buttons = (
        <Button
          size="large"
          fullWidth
          onClick={handleSignWithSignedModal}
          leftIcon="ok"
          loading={isLoading}>
          {t('common_sign')}
        </Button>
      );
    }
  }

  return (
    <>
      {buttons}
      <TextAreaModal
        formFromProps={form}
        actions={
          <div className={s.rejectModalActions}>
            <Button size="large" fullWidth ghost htmlType="reset" loading={isLoading}>
              {t('common_cancellation')}
            </Button>
            <Button
              size="large"
              fullWidth
              htmlType="submit"
              rightIcon="arrowRight"
              loading={isLoading}
              disabled={!comment}>
              {t('documents_send_reject')}
            </Button>
          </div>
        }
        isRightIconAvailable={false}
        modalHeaderTitle={t('documents_approval_reject')}
        name="comment"
        title={t('common_reject_reason')}
        maxLength={360}
        isModalOpen={modalType === MODAL_TYPE.REJECT_COMMENT}
        onSubmitHandler={
          assignment?.type === 'APPROVE' ? handleRejectConfirm : handleCancellationRejectConfirm
        }
        onReset={handleModalClose}
        setIsModalOpen={handleModalClose}
      />
      <RejectTaskModal isOpen={modalType === MODAL_TYPE.REJECTED} handleClose={handleModalClose} />
      <ApproveModal
        isOpen={modalType === MODAL_TYPE.APPROVE}
        isApprove
        handleClose={handleModalClose}
      />
      <ApproveModal
        isOpen={modalType === MODAL_TYPE.FAMILIARIZED}
        isApprove={false}
        handleClose={handleModalClose}
      />
      <SignedModal isOpen={modalType === MODAL_TYPE.SIGNED_KEDO} handleClose={handleModalClose} />
    </>
  );
};

export default ActionsKedoTask;
