import { ReactNode, useContext, useState } from 'react';

import { t } from 'tools/i18n';

import { Button, Form } from 'gazprom-ui-lib';

import { AssignmentContext } from 'containers/document-assignment/documentsAssignment';
import TextAreaModal from 'containers/textarea-modal';

import ApproveModal from '../approve-modal';
import CorrectionTaskModal from '../correction-task-modal';
import FamiliarizedModal from '../familiarized-modal';
import RejectTaskModal from '../reject-task-modal';
import useSetModalFromLocationState from '../useSetModalFromLocationState.hook';
import { MODAL_TYPE } from '../utils';
import s from './ActionsKedoDocument.module.scss';
import {
  ACTIONS_AVAILABLE_STATUSES,
  ModalType,
  REJECT_AVAILABLE_STATUSES,
} from './actionsKedoDocument.utils';
import useActions from './useActions.hook';

interface Props {
  handleCertificateCheck: (callback: () => void) => void;
}

const ActionsKedoDocument = (props: Props) => {
  const { handleCertificateCheck } = props;
  const assignment = useContext(AssignmentContext);

  const [modalType, setModalType] = useState<ModalType>('');
  const [form] = Form.useForm();
  const comment = Form.useWatch('comment', form);

  const handleModalClose = () => {
    setModalType('');
  };

  const handleOpenRejectCommentModal = () => {
    setModalType(MODAL_TYPE.REJECT_COMMENT);
  };

  const handleOpenRevisionModal = () => {
    setModalType(MODAL_TYPE.REVISION_COMMENT);
  };

  useSetModalFromLocationState({ setModalType });

  const { handleSign, handleReject, handleRevision, isLoading } = useActions({
    assignment,
    handleCertificateCheck,
    setModalType,
  });

  const areActionsAvailable =
    assignment?.status?.code === 'WAITING' && ACTIONS_AVAILABLE_STATUSES.includes(assignment?.type);

  const isRejectAvailable =
    areActionsAvailable && REJECT_AVAILABLE_STATUSES.includes(assignment?.type);

  let buttons: ReactNode;

  if (assignment && areActionsAvailable) {
    const isVacationType = assignment?.type === 'VACATION_SIGN_EMPLOYEE';

    const handleSignDocument = () =>
      handleSign(isVacationType ? MODAL_TYPE.FAMILIARIZED_KEDO : MODAL_TYPE.SIGNED);

    buttons = (
      <>
        <Button
          onClick={handleSignDocument}
          fullWidth
          size="large"
          leftIcon="ok"
          type="primary"
          loading={isLoading}>
          {isVacationType ? t('common_familiarize') : t('common_sign')}
        </Button>
        {isRejectAvailable && (
          <Button
            onClick={handleOpenRejectCommentModal}
            fullWidth
            size="large"
            danger
            ghost
            loading={isLoading}>
            {t('common_reject')}
          </Button>
        )}
        {/* <Button
          leftIcon="return"
          fullWidth
          size="large"
          ghost
          onClick={handleOpenRevisionModal}
          loading={isLoading}>
          {t('documents_send_to_correction')}
        </Button> */}
      </>
    );
  }

  return (
    <>
      {buttons}
      <TextAreaModal
        formFromProps={form}
        actions={
          <div className={s.rejectModalActions}>
            <Button size="large" fullWidth ghost htmlType="reset" loading={isLoading}>
              {t('common_cancellation')}
            </Button>
            <Button
              size="large"
              fullWidth
              htmlType="submit"
              rightIcon="arrowRight"
              loading={isLoading}
              disabled={!comment}>
              {t('documents_send_reject')}
            </Button>
          </div>
        }
        isRightIconAvailable={false}
        modalHeaderTitle={t('documents_approval_reject')}
        name="comment"
        title={t('common_reject_reason')}
        maxLength={360}
        isModalOpen={modalType === MODAL_TYPE.REJECT_COMMENT}
        onSubmitHandler={handleReject}
        onReset={handleModalClose}
        setIsModalOpen={handleModalClose}
      />
      {/* <TextAreaModal
        formFromProps={form}
        actions={
          <div className={s.rejectModalActions}>
            <Button size="large" fullWidth ghost htmlType="reset" loading={isLoading}>
              {t('common_cancellation')}
            </Button>
            <Button
              size="large"
              fullWidth
              htmlType="submit"
              rightIcon="arrowRight"
              loading={isLoading}
              disabled={!comment}>
              {t('common_send')}
            </Button>
          </div>
        }
        isRightIconAvailable={false}
        modalHeaderTitle={t('documents_send_to_correction_label')}
        name="comment"
        title={t('documents_send_to_correction_reason')}
        maxLength={360}
        isModalOpen={modalType === MODAL_TYPE.REVISION_COMMENT}
        onSubmitHandler={handleRevision}
        onReset={handleModalClose}
        setIsModalOpen={handleModalClose}
      /> */}
      <RejectTaskModal isOpen={modalType === MODAL_TYPE.REJECTED} handleClose={handleModalClose} />
      <CorrectionTaskModal
        isOpen={modalType === MODAL_TYPE.CORRECTION}
        handleClose={handleModalClose}
      />
      <ApproveModal
        isOpen={modalType === MODAL_TYPE.SIGNED}
        isApprove={false}
        handleClose={handleModalClose}
      />
      <FamiliarizedModal
        isOpen={modalType === MODAL_TYPE.FAMILIARIZED_KEDO}
        handleClose={handleModalClose}
      />
    </>
  );
};

export default ActionsKedoDocument;
