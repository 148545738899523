import { t } from 'tools/i18n';

import { EducationType } from 'types/employee.types';

import { PersonalDataItemType } from '../profile-personal-data-details-general/profilePersonalDataDetailsGeneral.utils';

export const getEducationItems = (educationData: EducationType): PersonalDataItemType[] => [
  {
    title: t('my_personal_data_education_name'),
    value: educationData?.educationName,
    fullWidth: true,
  },
  {
    title: t('my_personal_data_education_type'),
    value: educationData?.educationType,
    fullWidth: true,
  },
  {
    title: t('my_personal_data_education_graduate_year'),
    value: educationData?.educationDate,
    date: true,
  },
];
