import React from 'react';

import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface Props {
  isOpened: boolean;
  onCancel: () => void;
}

const DownloadErrorModal = (props: Props) => {
  const { isOpened, onCancel } = props;

  return (
    <ModalDefault open={isOpened} onCancel={onCancel} width={300}>
      <ModalDefault.Icon name="info" type="danger" />
      <ModalDefault.Title>{t('documents_status_error_label')}</ModalDefault.Title>
      <ModalDefault.Description>{t('common_error_description')}</ModalDefault.Description>
      <ModalDefault.Actions>
        <Button type="secondary" size="large" fullWidth onClick={onCancel}>
          {t('common_close')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default DownloadErrorModal;
