import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const SignedModal = (props: Props) => {
  const { isOpen, handleClose } = props;

  return (
    <ModalDefault key="signModalTaskModal" open={isOpen} onCancel={handleClose} width={300}>
      <ModalDefault.Icon name="ok" type="success" />
      <ModalDefault.Title>{t('common_signed_single')}</ModalDefault.Title>
      <ModalDefault.Description>{t('documents_signed_doc')}</ModalDefault.Description>
      <ModalDefault.Actions>
        <Button size="large" fullWidth onClick={handleClose}>
          {t('common_ok')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default SignedModal;
