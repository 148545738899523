import React, { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import {
  DOCUMENTS_BUSINESS_TRIP_ROUTE,
  getDocumentsBusinessTripDetailsRoute,
} from 'routes/documents/list';
import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

import BusinessTripForm from 'containers/business-trip-form/businessTripForm';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import useHandleSubmit from './useHandleSubmit.hook';

const DocumentsBusinessTripDetailsCancel = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [showModal, setShowModal] = useState<boolean>(false);

  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => {
    setShowModal(false);
    navigate(DOCUMENTS_BUSINESS_TRIP_ROUTE);
  };

  const headerChildren = (
    <>
      <Header.GoBack to={getDocumentsBusinessTripDetailsRoute(id!)} />
      <Header.Label label={t('documents_business_trip_cancel')} />
    </>
  );

  const { isLoading, handleCancel } = useHandleSubmit({ handleModalOpen });

  return (
    <>
      <WithStatusBar>
        <WithNavigation headerChildren={headerChildren}>
          <WithBackground>
            <BusinessTripForm type="cancel" isLoading={isLoading} handleSubmit={handleCancel} />
          </WithBackground>
        </WithNavigation>
      </WithStatusBar>
      <ModalDefault open={showModal} onCancel={handleModalClose} width={300}>
        <ModalDefault.Icon name="ok" type="success" />
        <ModalDefault.Title>{t('common_request_canceled')}</ModalDefault.Title>
        <ModalDefault.Description>
          {t('documents_business_trip_cancel_description')}
        </ModalDefault.Description>
        <ModalDefault.Actions>
          <Button fullWidth size="large" rightIcon="arrowRight" onClick={handleModalClose}>
            {t('common_ok')}
          </Button>
        </ModalDefault.Actions>
      </ModalDefault>
    </>
  );
};

export default DocumentsBusinessTripDetailsCancel;
