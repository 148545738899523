import { ReactNode, useEffect } from 'react';

import { t } from 'tools/i18n';

import { Button, Form, FormInstance, Modal, TextArea, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';

import s from './TextareaModal.module.scss';

interface TextAreaModalProps<R> {
  formFromProps?: FormInstance;
  actions?: ReactNode;
  name: string;
  modalHeaderTitle?: string;
  title: string;
  maxLength: number;
  isModalOpen: boolean;
  isRightIconAvailable?: boolean;
  defaultValue?: string;
  onReset?: () => void;
  setIsModalOpen: (value: boolean) => void;
  onSubmitHandler: (value: R) => void;
}

const TextAreaModal = <R = Record<string, string>,>(props: TextAreaModalProps<R>) => {
  const {
    isRightIconAvailable = true,
    formFromProps,
    actions,
    modalHeaderTitle = t('common_add_text'),
    name,
    isModalOpen,
    title,
    maxLength,
    defaultValue,
    setIsModalOpen,
    onSubmitHandler,
    onReset,
  } = props;

  const [form] = Form.useForm();

  const formToUse = formFromProps ?? form;

  const text = Form.useWatch(name, formToUse) ?? '';
  const isNotEmpty = !!text.length;
  const showRightIcon = isRightIconAvailable && isNotEmpty;

  const handleClose = () => {
    if (!isNotEmpty) {
      onSubmitHandler(text);
    }

    setIsModalOpen(!isModalOpen);
  };

  const handleOk = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    onSubmitHandler(text);
  };

  const header = (
    <>
      <Header.GoBackButton onClick={handleClose} />
      <Header.Label label={modalHeaderTitle} />
      {showRightIcon && <Header.RightIcon onClick={handleOk} iconName="ok" />}
    </>
  );

  useEffect(() => {
    if (defaultValue) {
      formToUse.setFieldValue(name, defaultValue);
    }
  }, [defaultValue, formToUse, name]);

  const defaultActions = isNotEmpty && (
    <Button htmlType="submit" leftIcon="ok" fullWidth>
      {t('common_add')}
    </Button>
  );

  return (
    <Modal
      open={isModalOpen}
      className={s.textAreaModal}
      wrapClassName={s.wrapClassName}
      transitionName="">
      <Form form={formToUse} onFinish={onSubmitHandler} onReset={onReset} className={s.form}>
        <WithNavigation headerChildren={header}>
          <WithBackground>
            <Card>
              <div className={s.content}>
                <Typography.Title level={4}>
                  <span dangerouslySetInnerHTML={{ __html: title }} />
                </Typography.Title>
                <Typography.Text size="12" type="secondary">
                  {t('common_symbols_count', {
                    currentCount: text.length,
                    maxCount: maxLength,
                  })}
                </Typography.Text>
                <Form.Item name={name} initialValue={defaultValue} normalize={(e) => e.trimStart()}>
                  <TextArea
                    className={s.text}
                    rows={10}
                    placeholder={t('common_enter_text')}
                    maxLength={maxLength}
                  />
                </Form.Item>
              </div>
              {actions ?? defaultActions}
            </Card>
          </WithBackground>
        </WithNavigation>
      </Form>
    </Modal>
  );
};

export default TextAreaModal;
