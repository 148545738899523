import React, { ReactElement } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import {
  DOCUMENTS_STATEMENTS_ROUTE,
  getDocumentsStatementRequestRoute,
} from 'routes/documents/list';
import { GUIDE_ROUTE } from 'routes/guide/list';
import { HOME_NEWS_ROUTE, getNewsWithIdRoute } from 'routes/home/list';
import { PROFILE_ROUTE } from 'routes/profile/list';
import { t } from 'tools/i18n';
import { createMediaDownloadUrl } from 'utils/createMediaUrl.utils';
import useBuildHero from 'utils/useBuildHero';
import useCertificate from 'utils/useCertificate.hook';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';
import useIsSignEnabled from 'utils/useIsSignEnabled.hook';

import { useGetLatestNewsQuery } from 'services/article/articleApiService';
import { getHeroConfigSelector } from 'services/me/meApiService';
import { useAppSelector } from 'store';

import { Icon, Typography } from 'gazprom-ui-lib';

import Bio from 'components/bio';
import InappLink from 'components/inappLink';
import UniversalLoader from 'components/universal-loader';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import buildHero from 'assets/icons/buildHero.png';
import gazPromBank from 'assets/icons/gazPromBank.png';
import gazmagaz from 'assets/icons/gazmagaz.png';
import stroyGeroy from 'assets/icons/stroyGeroy.png';

import { ArticleFullType } from 'types/article.types';

import s from './Home.module.scss';
import HomeMood from './home-mood';
import HomeNewYear from './home-new-year';
import HomeSurveys from './home-surveys';
import { INNER_LINKS, SOCIAL_NETWORKS } from './home.utils';
import useHomeMoodHook from './useHomeMood.hook';

const Home = () => {
  const navigate = useNavigate();

  const { data } = useAppSelector(getHeroConfigSelector);

  const {
    showMoodAsModal,
    showMoodLastTime,
    showMoodFirstTime,
    handleCloseMoodFirst,
    handleMoodSubmit,
    handleSwipe,
    handleCloseMoodLast,
  } = useHomeMoodHook();

  const [selectedEmployee] = useFullSelectedEmployee();

  const { data: news, isLoading } = useGetLatestNewsQuery();

  const { isBuildHeroFlag } = useBuildHero();

  const { isActive: isCertificateActive } = useCertificate();

  const signEnabled = useIsSignEnabled();

  const headerChildren = (
    <>
      <Header.Logo />
      <Header.Notification />
    </>
  );

  const GAZ_PROM_BANK_STATEMENT_ID = process.env.96f399ad-181f-4013-a81e-079e19843502?.trim();

  const renderNews = (article: ArticleFullType) => {
    const imageSrc = createMediaDownloadUrl(`${article.cover?.link}/512x304`);

    const handleNavigate = () =>
      navigate(getNewsWithIdRoute(article.code), { state: { type: 'news' } });

    return (
      <button className={s.news} onClick={handleNavigate} key={article.id}>
        {article.cover?.link ? (
          <div
            className={s.image}
            style={{
              backgroundImage: `url(${imageSrc})`,
            }}
          />
        ) : (
          <div className={s.noImage}>
            <Icon name="noImage" />
          </div>
        )}
        <div className={s.articleWrapper}>
          <Typography.Text size="12">{article.title}</Typography.Text>
        </div>
      </button>
    );
  };

  const renderSocialNetwork = (soc: { link: string; icon: ReactElement }) => {
    const { icon, link } = soc;
    return (
      <InappLink href={link} className={s.socialNetwork} key={link}>
        {icon}
      </InappLink>
    );
  };

  const renderInnerLinks = (soc: { link: string; icon: string; label: string }) => {
    const { icon, link, label } = soc;
    return (
      <Link to={link} className={s.innerLink} key={link}>
        <img src={icon} alt="icon" />
        <Typography.Text size="12">{label}</Typography.Text>
      </Link>
    );
  };

  const handleNavigateToNews = () => navigate(HOME_NEWS_ROUTE);

  const handleNavigateToGuid = () => navigate(GUIDE_ROUTE);

  const handleNavigateToStatement = (id?: string) =>
    navigate(id ? getDocumentsStatementRequestRoute(id) : DOCUMENTS_STATEMENTS_ROUTE);

  const { firstName = '', lastName = '' } = selectedEmployee?.person || {};

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <Card.Navigate to={PROFILE_ROUTE} className={s.profile}>
            <Bio
              titleLevel={4}
              firstName={firstName}
              lastName={lastName}
              staffName={t('profile_description')}
            />
            <Icon name="forward" />
          </Card.Navigate>
          <div className={s.innerLinksCard}>
            <div className={s.innerLinksWrapper}>{INNER_LINKS.map(renderInnerLinks)}</div>
          </div>
          <Card withPadding={false}>
            <button className={s.newsHeader} onClick={handleNavigateToNews}>
              <Typography.Title level={4}>{t('common_newsline')}</Typography.Title>
              <Icon name="arrowRight" />
            </button>
            <WithLoader
              loader={
                <div className={s.loaderWrapper}>
                  <UniversalLoader />
                </div>
              }
              isLoading={isLoading}>
              <div className={s.newsWrapper}>
                {news?.map(renderNews)}
                <button onClick={handleNavigateToNews} className={s.moreButton}>
                  <Icon name="arrowRight" color="primary6" />
                  <Typography.Text size="12" className={s.more} type="primary" weight="500">
                    {t('common_more')}
                  </Typography.Text>
                </button>
              </div>
            </WithLoader>
          </Card>
          {showMoodFirstTime && (
            <HomeMood handleMoodSubmit={handleMoodSubmit} handleClose={handleCloseMoodFirst} />
          )}

          <HomeNewYear />

          {isBuildHeroFlag && (
            <button className={s.buildHero} onClick={handleNavigateToGuid}>
              <img src={buildHero} alt="buildHero" />
            </button>
          )}

          {!!GAZ_PROM_BANK_STATEMENT_ID && isCertificateActive && signEnabled && (
            <button
              className={s.buildHero}
              onClick={() => handleNavigateToStatement(GAZ_PROM_BANK_STATEMENT_ID)}>
              <img src={gazPromBank} alt="gazPromBank" />
            </button>
          )}

          {!!data?.isHero && (
            <InappLink
              href={`https://eventrocks.app/?login=${data.login}&networkingCode=${data.code}&access-code=%D1%81%D1%82%D1%80%D0%BE%D0%B9%D0%B3%D0%B5%D1%80%D0%BE%D0%B9#/event/4846`}
              className={s.banner}>
              <img src={stroyGeroy} alt="stroyGeroy" />
            </InappLink>
          )}
          <InappLink href="https://gzmz.ru/" className={s.banner}>
            <img src={gazmagaz} alt="gazmagaz" />
          </InappLink>
          <HomeSurveys />
          {showMoodLastTime && (
            <HomeMood handleMoodSubmit={handleMoodSubmit} handleClose={handleCloseMoodLast} />
          )}
          <Card className={s.socialNetworks}>
            <Typography.Text size="16">{t('common_follow_company_life')}</Typography.Text>
            <div>{SOCIAL_NETWORKS.map(renderSocialNetwork)}</div>
          </Card>
          {showMoodAsModal && (
            <HomeMood handleSwipe={handleSwipe} handleMoodSubmit={handleMoodSubmit} isModal />
          )}
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default Home;
