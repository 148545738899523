import React from 'react';

import { t } from 'tools/i18n';

import { Radio } from 'gazprom-ui-lib';

export const renderDayOff = (dayOff: string) => {
  const labelAndValue = t(`common_enum_day_off_${dayOff.toLowerCase()}`);

  return (
    <Radio.Button key={labelAndValue} value={labelAndValue.toLowerCase()}>
      {labelAndValue}
    </Radio.Button>
  );
};

export enum DAY_OFF_TYPE_ENUM {
  REGULAR = 'REGULAR',
  DOUBLE = 'DOUBLE',
}
